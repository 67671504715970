import * as React from 'react';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';

function HeaderMenu(props) {
    const navigate = useNavigate();

    return (
        <div className="header-container">
            <Button variant="outlined" style={{ borderColor: '#d67729'}} className="header-button" onClick={() => navigate('/biografija')}>
                <p className="header-button-text">Biografija</p>
            </Button>
            <Button variant="outlined" style={{ borderColor: '#d67729'}} className="header-button" onClick={() => navigate('/usluge')}>
                <p className="header-button-text">Usluge</p>
            </Button>
            <Button variant="outlined" style={{ borderColor: '#d67729'}} className="header-button" onClick={() => navigate('/iskustva')}>
                <p className="header-button-text">Iskustva</p>
            </Button>
            <Button variant="outlined" style={{ borderColor: '#d67729'}} className="header-button" onClick={() => navigate('/science')}>
                <p className="header-button-text">Blog</p>
            </Button>
            <Button variant="outlined" style={{ borderColor: '#d67729'}} className="header-button" onClick={() => navigate('/najcesca-pitanja')}>
                <p className="header-button-text">Pitanja</p>
            </Button>
            <Button variant="outlined" style={{ borderColor: '#d67729'}} className="header-button" onClick={() => navigate('/kontakt')}>
                <p className="header-button-text">Kontakt</p>
            </Button>
        </div>
    );
}

export default HeaderMenu;

