import FreeConsultationImage from '../Images/free_consultation.jpg';
import IndividualConsultationImage from '../Images/individual_consultation.jpg';
import BasicPackageImage from '../Images/basic_package.jpg';
import IntensivePackageImage from '../Images/intensive_package.jpg';
import WebinarsImage from '../Images/webinars.jpg';

const services = [
    {
        id: 'besplatne-konsultacije',
        title: 'Besplatne online konsultacije',
        image: FreeConsultationImage,
        content: `<h2>Besplatne online konsultacije</h2>
                  <p>Da li te zanima kako ti moje konsultacije mogu pomoći da ostvariš svoje ciljeve?</p>
                  <p>Zakaži svoj besplatan online sastanak u trajanju od 15 minuta! Na ovom kratkom sastanku moćićemo da se:</p>
                  <ul>
                    <li><strong>Upoznamo</strong>: Popričamo o tvojim ciljevima, zdravlju i životu</li>
                    <li><strong>Istražimo problem</strong>: Postaviću ti nekoliko pitanja kako bih razumeo izazove pred tobom</li>
                    <li><strong>Upoznaš sa mojim pristupom</strong>: Objasniću ti moje metode, pristup i benefite rada sa mnom</li>
                    <li><strong>Postaviš mi pitanja</strong>: Imaćeš priliku da mi postaviš bilo koje pitanje vezano za moje usluge</li>
                  </ul>
                  <p>Ovaj besplatan prvi sastanak te ne obavezuje na kasniju saradnju i dobra je prilika da proveriš da li možemo da uspešno radimo zajedno na ostvarenju tvojih nutritivnih i zdravstvenih ciljeva.</p>`,
    },
    {
        id: 'individualne-konsultacije',
        title: 'Individualne konsultacije',
        image: IndividualConsultationImage,
        content: `<h2>Individualne konsultacije</h2>
                  <p>Spreman si da poboljšaš svoju ishranu već danas? Jedan na jedan konsultacije sa mnom će ti pomoći da ostvariš svoje ciljeve. Nezavisno od toga da li želiš da se razgovaramo uživo ili radimo online, konsultacije traju sat vremena i tokom njih možeš očekivati:</p>
                <ul>
                    <li><strong>Analizu trenutnog stanja</strong>: Počećemo od razmatranja tvoje zdravstvene istorije, životnog stila i navika u ishrani. Takođe, izmerićemo kilažu i procente masti i mišića u telu, kako bismo postavili jasnu sliku početne tačke.</li>
                    <li><strong>Postavljanje cilja</strong>: Zajedno ćemo razmisliti o realističnom i jasnom cilju koji se uklapa u tvoju viziju sebe</li>
                    <li><strong>Pravljenje plana</strong>: Kako bismo ostvarili tvoj cilj neophodno je da osmislimo korake koji će nas do njega dovesti. Predložiću ti nekoliko opcija i pružiti ti preporuke za izbor hrane, veličinu porcija i druge stvari koje podržavaju tvoj napredak</li>
                    <li><strong>Motivacija i podrška</strong>: Istražićemo tvoju motivaciju i izgraditi samopouzdanje kako bi nastavio ka svojim ciljevima.</li>
                </ul>
                <p>Dok jedne konsultacije mogu da pruže vredno znanje i budu početna tačka za promenu, moja preporuka je da isplaniraš nekoliko sastanaka kako bismo postigli trajnu promenu.</p>
                <p><strong>Cena individualnih konultacija (1h) je 4.000RSD ili 40EUR za uplate iz inostranstva.</strong></p>`,
    },
    {
        id: 'osnovni-paket-konsultacija',
        title: 'Osnovni paket konsultacija',
        image: BasicPackageImage,
        content: `<h2>Osnovni paket konsultacija</h2>
                <p>Spreman si da se posvetiš svom zdravlju i postigneš dugorične rezultate. Pomoćiću ti mojim sveobuhvatnim paketima konsultacija. Paketi nude strukturu i podršku i pomažu ti da postaneš ekspert za svoju ishranu i zdravlje.</p> 
                <ul>
                    <li>6 individualnih konsultacija u trajanju od 1 sata</li>
                    <li>Individualni plan koraka za unapređenje ishrane</li>
                    <li>Podršku i vođstvo između konsultacija</li>
                    <li>Edukativni materijal</li>
                </ul>
                <p><strong>Cena: 20.000RSD ili 200EUR za uplate iz inostranstva.</strong></p>
                <p>Osnovni paket je idealan za motivisane i odlučne ljude kojima znači da dobiju podršku i vođstvo.</p>`,
    },
    {
        id: 'intenzivni-paket-konsultacija',
        title: 'Intenzivni paket konsultacija',
        image: IntensivePackageImage,
        content: `<h2>Intenzivni paket konsultacija</h2>
            <p>Intenzivni paket uključuje sve u Osnovnom paketu, PLUS:</p>      
        <ul>
                    <li>6 dodatnih 15-minutnih poziva između konsultacija za veći nivo podrške</li>
                    <li>Prioritet u zakazivanju sastanaka i brže vreme odgovora</li>
                </ul>
                <p><strong>Cena: 32.000RSD ili 320EUR za uplate iz inostranstva.</strong></p>
                <p>Ovaj paket namenjen je onima koji žele češći razgovor i više praćenja tokom celokupnog procesa.</p>,`
            },
    {
        id: 'predavanja-i-vebinari',
        title: 'Predavanja i Webinari',
        image: WebinarsImage,
        content: `<h2>Predavanja i Webinari</h2>
                  <p>Ohrabri svoje zaposlene da brinu o svom zdravlju i ishrani uz pomoć mojih informativnih i inspirišućih predavanja o nutriciji i dobrostanju. Nudim prilagođena predavanja koja se mogu dobro uklopiti u akcije tvoje kompanije poput:</p>
                <ul>
                    <li>Ishrana u modernom svetu (Ishrana i navike I)</li>
                    <li>Psihologija ishrane (Ishrana i navike II)</li>
                    <li>Hrana za doba anksioznosti</li>
                    <li>Nutricija za produktivnost</li>
                </ul>
                <p>Kontaktiraj me kako bismo popričali o potrebama tvoje firme i dogovorili se oko detalja predavanja ili vebinara.</p>`,
    },
];

export default services;
