import './App.css';
import './Styles.css';
import Home from './home/home';
import Contact from './contact/contact';
import Science from './food_science/science';
import Biography from './biography/biography';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScienceDetail from './food_science/science_detail';
import FForms from './google_form/first_form';
import FAQ from './faq/questions'
import Services from './service/ServicePage'
import Testimonials from './testemonial/TestimonialsPage'
import Companies from './landing_pages/companies'
import Hotels from './landing_pages/hotels'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
        <Route path="/"  element={<Home></Home>} />
          <Route path="/biografija"  element={<Biography> </Biography>} />
          <Route path="/kontakt"  element={<Contact></Contact>} />
          <Route path="/science"  element={<Science></Science>} />
            <Route path="/science/blog_post/1"  element={<ScienceDetail></ScienceDetail>} />
            <Route path="/science/blog_post/2"  element={<ScienceDetail></ScienceDetail>} />
            <Route path="/science/blog_post/3"  element={<ScienceDetail></ScienceDetail>} />
          <Route path="/science/blog_post/:id"  element={<ScienceDetail></ScienceDetail>} />
          <Route path="/firstform"  element={<FForms></FForms>} />
          <Route path="/najcesca-pitanja" element={<FAQ></FAQ>}/>
          <Route path="/usluge" element={<Services></Services>}/>
          <Route path="/iskustva" element={<Testimonials />} />
          <Route path="/ponuda-za-kompanije" element={<Companies />} />
          <Route path="/ponuda-za-hotele" element={<Hotels />} />
          
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
