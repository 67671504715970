import React from 'react'
import { IconButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Menu from './menu'
import { Link } from 'react-router-dom';
import HeaderMenu from './header_menu.js';


const Header = () => {
    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between',flexWrap: 'wrap', paddingTop: '16px', height: '10VH', alignItems: 'center',paddingLeft:'4vW', paddingRight:'4vW' }}>
            <Link to="/"> <img src={`${process.env.PUBLIC_URL}/logo.webp`} className="logo" alt="aragonski nutrition" width="300" height="73"/> </Link>
            <div style={{marginLeft: 'auto'}} className='hide-element-on-small'>
                <HeaderMenu/>
            </div>
            <div className='hide-element-on-big'>
            <IconButton onClick={handleClickOpen} >
                <MenuIcon style={{ fontSize: '4vH', color: 'white' }} ></MenuIcon>
            </IconButton>
            </div>
            <Dialog
                open={open}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        backgroundColor: 'transparent',
                        boxShadow: 'none',
                    },
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth='60VW'

            >
                <DialogContent style={{ padding: '0', borderRadius: '25px' }}>
                    <Menu></Menu>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Header