import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';
import FirstImage from '../blog_images/5_principa/img1.jpg'
import SecondImage from '../blog_images/5_principa/img2.jpg'
import ThirdImage from '../blog_images/5_principa/img3.jpg'
import FifthImage from '../blog_images/5_principa/img5.jpg'
import SixthImage from '../blog_images/5_principa/img6.jpg'
import SeventhImage from '../blog_images/5_principa/img7.jpg'
import EighthImage from '../blog_images/5_principa/img8.jpg'
import NinethImage from '../blog_images/5_principa/img9.jpg'

const BlogPost7 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>
<p>Živimo u svetu opsednutom dijetama. Svuda oko nas su reklame za "čarobne pilule", "revolucionarne metode" i "brze rezultate". Međutim, istina je da restriktivne dijete i različiti preparati za mršavljenje retko daju dugoročne rezultate. Čak i ako uspete da izgubite kilograme uz pomoć neke dijete ili preparata, velika je verovatnoća da ćete ih vratiti (često i sa kamatom) čim se vratite "normalnom" načinu ishrane i života.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Šta je druga opcija? Fokusirajte se na usvajanje zdravih navika i promenu načina života. Mršavljenje ne treba da bude kratkoročni cilj, već posledica vašeg putovanja ka dobrom zdravlju i još boljim navikama.</p>
<p>U ovom postu predstaviću vam <strong>pet principa za dugoročni uspeh u mršavljenju </strong>bez restriktivnih dijeta i prevelikog odricanja. Ovi principi, iako se delom zasnivaju na naučnim saznanjima, pretežno su nastali kao rezultat mog praktičnog iskustva u radu sa klijentima i mogu vam pomoći da postignete željenu težinu na način koji je zdrav i dugoročno održiv.</p>
<h2><strong>Princip 1: Uobročavanje - Imajte vreme kada jedete i vreme kada ne jedete</strong></h2>
<p>Da li ste ikada "smazali" kesicu čipsa dok ste gledali film, a da niste ni primetili? Ili ste možda pojeli kutiju keksa dok ste radili za računarom, da biste kasnije o tom događaju saznali tek na osnovu mrvica koje su ostale na stolu? Ako jeste, nisti sami. Mnogi ljudi jedu "nesvesno", bez prave pažnje na ono što unose u svoj organizam i samim tim bez osećaja prave sitosti ili zadovoljstva.</p>
<p>Uobročavanje je jednostavan, ali moćan princip koji vam može pomoći da povratite kontrolu nad svojom ishranom. U suštini, uobročavanje znači <strong>"zaokruživanje obroka"</strong> ili određivanje <strong>jasnog početka i kraja</strong> svakog obroka.</p>
<SquareImage src={SecondImage} alt="Description">
<h3><strong>Kako da se uobročite?</strong></h3>
<ol>
  <li><strong>Obrok ima početak i kraj:</strong> Idealno bi bilo da obrok traje između 20 i 30 minuta, ali nema potrebe za žurbom. Bitno je da krenete da jedete, da jedete, a onda i da u nekom trenutku završite sa jelom.</li>
  <li><strong>Sedite za sto:</strong> Izbegavajte da jedete "s nogu", ispred televizora ili za računarom, dok ste zapravo usresređeni na nešto drugo.</li>
  <li><strong>Fokusirajte se na hranu:</strong> Obratite pažnju na ukuse, mirise i teksture hrane koju jedete.</li>
  <li><strong>Jedite polako:</strong> Odnosno, ne žurite. Izdvojte vreme za obroke.</li>
  <li><strong>Završite obrok:</strong> Kada završite sa jelom, napravite pauzu do sledećeg obroka, a u međuvremenu izbegavajte grickanje kada niste zaista gladni.</li>
</ol>
</SquareImage>
<h3><strong>Šta se računa kao obrok?</strong></h3>
<p>Sve što unosite u toku 30 minuta od prvog zalogaja računa se kao <strong>jedan obrok.</strong> Dakle, užina je obrok, jedna jabuka je obrok, dva keksa koja se kasnije ni ne sećate su obrok. To uključuje i kafu sa mlekom i šećerom, sokove, smoothie-je, itd.</p>
<h3><strong>Šta se NE računa kao obrok?</strong></h3>
<p>Voda, kafa bez šećera i mleka, kisela voda, limunada i čaj bez šećera i mleka <strong>nisu obrok.</strong>
</p>
<h3><strong>Zašto je uobročavanje važno?</strong></h3>
<p>Ljudsko telo nije napravljeno da konstantno vari. Napravljeno je tako da mu je potrebno da postoji ograničeno vreme za unos hrane, da obrok počne i da se završi, a da nakon toga ono ima vremena da unetu hranu svari.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<p>Jedan od problema današnjice jeste što je hrana uvek i svuda dostupna, izložena takoreći na dohvat ruke, tako da veliki broj ljudi, nesvesno, ima samo jedan obrok koji zapravo traje i po 12 sati u toku dana.</p>
<p>Druge prednosti uobročavanja:</p>
<ul>
  <li>Pomaže vam da <strong>jedete svesno</strong> i da <strong>bolje osetite signale sitosti.</strong></li>
  <li>Sprečava vas da <strong>jedete "bez smisla i reda".</strong></li>
  <li>Pomaže vam da <strong>razvijete zdraviji odnos sa hranom.</strong></li>
  <li>Doprinosi <strong>boljem varenju.</strong></li>
</ul>
<p>Uobročavanje možda deluje kao mala promena, ali <strong>može da ima veliki uticaj na vaše navike u ishrani i na vaše zdravlje. </strong>Velika većina ljudi koji su mi se obratili sa problemom gojaznosti nije bila uobročena i često nije imala ni jedan fiksan obrok u toku dana.</p>
</SquareImageLeft>
<Conclusion>Uobročavanjem preuzimate kontrolu nad svojom ishranom i učite da jedete svesno. Ova jednostavna promena može imati veliki uticaj na vaše zdravlje i navike u ishrani.</Conclusion>
<h2><strong>Princip 2: Fiksiranje broja obroka - Doslednost je ključ</strong></h2>
<p>Koliko obroka dnevno jedete? Tri? Pet? Ili možda jedete kada osetite glad i nemate fiksan broj obroka u toku dana?</p>
<p>Poslednja opcija najčešće je zaokruživan odgovor kod klijenata koji mi se obraćaju za pomoć sa kontrolom težine. Iako se često priča o "idealnom" broju obroka, <strong>ne postoji čarobni broj koji odgovara svima.</strong> Ono što je važno jeste da<strong> imate konstantan broj obroka </strong>svakog dana.</p>
<p>Za većinu ljudi, <strong>optimalan broj obroka je između 3 i 5.</strong> Međutim, prihvatljivo je i da imate 2 ili 7 obroka dnevno<strong>,</strong> sve dok je to konstantno.</p>
<h3><strong>Zašto je važno fiksirati broj obroka?</strong></h3>
<ul>
  <li><strong>Sprečava "grickanje" između obroka:</strong> Kada imate određen broj obroka, manja je verovatnoća da ćete jesti "bez smisla i reda", pa ćete tako smanjiti i količinu nezdrave hrane i količinu kalorija.</li>
  <li><strong>Olakšava planiranje obroka:</strong> Kada znate koliko obroka ćete imati danas, lakše je da dan unapred isplanirate šta ćete jesti, te da pripremite sve potrebne namirnice i obroke.</li>
  <li><strong>Pomaže u regulaciji apetita:</strong> Redovni obroci <a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/30925707/">pomažu</a> da održite stabilan nivo šećera u krvi i da izbegnete nagle napade gladi. Ovo dalje ima uticaj na poboljšanje hormona gladi i sitosti, a samim tim doprinosi lakšoj kontroli telesne težine.</li>
</ul>
<SquareImage src={FifthImage} alt="Description">
<h3><strong>Kako da fiksirate broj obroka?</strong></h3>
<ul>
  <li><strong>Odredite koliko obroka želite da imate u toku dana.</strong> Uzmite u obzir svoj životni stil, obaveze i preference. Ovo može zahtevati nekoliko pokušaja, otuda što ne možete zaista da znate šta vam prija dok ne isprobate i vidite šta vam odgovora u skladu sa vašim ritmom u toku dana.</li>
  <li><strong>Napravite plan obroka.</strong> Ovde ne mislim na konkretne obroke koje ćete imati u toku svakog dana, već na naziv obroka koji ćete imati i šta otprilike jedete u koje vreme. Primera radi: <em>Volim da jedem sladak doručak</em> ili <em>Jaja će često biti deo večere.</em></li>
  <li><strong>Pridržavajte se plana što je više moguće.</strong> Naravno, budite fleksibilni i prilagodite se nepredviđenim situacijama, ali zapamtite da je na svaku promenu telu potrebno neko vreme da se navikne. Za promenu rasporeda obroka uglavnom je potrebno 2 do 3 nedelje, tako da dajte sebi vremena.</li>
</ul>
</SquareImage>
<p>Fiksiranje broja obroka možda deluje kao jednostavna promena, ali može da ima veliki uticaj na vaše navike u ishrani i na vaše zdravlje. Takođe, može se pokazati teže nego što vam se učini s početka. Primetite šta i kako vam prija i dajte sebi vremena u procesu promene.</p>
<Conclusion>Odredite konstantan broj obroka i držite ga se što je više moguće. Ova mala promena može vam pomoći da regulišete apetit, poboljšate planiranje obroka i unesete više zdravih navika u svoj život.</Conclusion>
<h2><strong>Princip 3: Fizička aktivnost - Pokrenite svoje telo</strong></h2>
<p>Da li ste znali da fizička aktivnost ima pozitivan uticaj na bukvalno sve? Od mentalnog zdravlja i raspoloženja do imuniteta i prevencije hroničnih bolesti. Redovna fizička aktivnost je ključna za dobro zdravlje i blagostanje organizma.</p>
<RectangleImage src= {SixthImage} alt="Description"></RectangleImage>
<p>Kada je u pitanju mršavljenje, fizička aktivnost igra važnu ulogu. Pored toga što "sagoreva" kalorije, utiče na sastav tela i povećanje mišićne mase, što dalje ubrzava metabolizam i pomaže u održavanju željene težine povećanjem energetskih potreba. Sa druge strane, iako je fizička aktivnost potrebna kako bi se lakše kontrolisala težina, ona nije dovoljna i neophodno je voditi računa i o ishrani.</p>
<p>Kada se spomene fizička aktivnost većina ljudi odmah pomisli na teretanu, kao da je to jedino moguće mesto za trening. Međutim, to apsolutno nije tako. Zato je prvo pitanje kojim želim da se bavim:</p>
<h3><strong>Šta je trening?</strong></h3>
<p>Trening je svaka fizička aktivnost koju radite za zdravlje i koju redovno<strong> </strong>ponavljate<strong>.</strong> To može biti <strong>trčanje, šetnja, vožnja bicikla, plivanje, ples, joga, teretana,</strong> ili bilo koja druga aktivnost koja vam prija i koja vas "umara".</p>
<p>Organizovani grupni treninzi imaju svoje prednosti jer postoji struktura koju vam pruža trener, trenirate u grupi i imate određene termine na koje treba da dođete, ali treba ih uklopiti sa drugim obavezama i morate da pratite ritam grupe. Prednost teretane i plivanja je što možete da trenirate kad god vama odgovara i imate odvojen prostor samo za treniranje. Međutim, ovde postoje i izazovi sa nalaženjem trenera i lokacije koja vam odgovara, a uz to je važno da imate disciplinu da ispoštujete svoj raspored treninga. Samostalni treninzi kod kuće, šetnja ili vožnja bicikla su dobar izbor ukoliko nemate fiksni raspored ili ne želite da budete okruženi drugim ljudima, ali je potrebna još veća disciplina i odlučnost da se odabrani raspored treninga i ispoštuje.</p>
<p>Sve u svemu, kakav god izbor da napravite, bitno je da imate redovnu fizičku aktivnost. Dodatno, nemojte se plašiti da isprobavate i eksperimentišete, nije sve za svakoga i pokušajte da nađete nešto što vas lično ispunjava. Nije cilj da se mučite, nije cilj da vam bude neprijatno i ne radite na tome da postanete profesionalni sportista.</p>
<SquareImage src={SeventhImage} alt="Description">
<p><strong>Šta NIJE trening?</strong></p>
<p>Često na pitanje da li trenirate dobijam odgovore poput: <em>Ne, ali je moj posao fizički aktivan po sebi</em> ili <em>Ne, ali imam dvoje male dece, pa se po ceo dan sa njima jurim</em>. Iako ovo jesu fizičke aktivnosti i, priznajemo, mogu da vas umore, ne treba ih mešati sa treningom. Aktivnosti koje radite kratko i povremeno, kao što su pranje sudova, sređivanje kuće ili šetnja do prodavnice, nisu trening.</p>
<p>Trening i fizička aktivnost se međusobno razlikuju po cilju i strukturi, ali smatram da su bitnije razlike između ova dva intenzitet i ponavljanje. Trening uglavnom za cilj ima trening, on ima strukturu i plan, većeg je intenziteta i uključuje redovno ponavljanje. Ove osobine ključne su za ono što trening čini toliko značajnim za zdravlje, tako da dok ste možda aktivni na poslu ili u kući to jeste izvesna fizička aktivnost, ali nije isto što i trening.</p>
</SquareImage>
<h3><strong>Zašto je važno da "trenirate"?</strong></h3>
<ul>
  <li><strong>Poboljšava fizičko zdravlje:</strong> <a rel="nofollow" href="https://www.cdc.gov/heart-disease/prevention/index.html">Jača kardiovaskularni sistem</a>, <a rel="nofollow" href="https://www.cdc.gov/physical-activity-basics/benefits/index.html">povećava gustinu kostiju i snagu mišića</a></li>
  <li><strong>Poboljšava mentalno zdravlje:</strong> <a rel="nofollow" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC1470658/#i1523-5998-8-2-106">Poboljšava</a> san, smanjuje anksioznost, stres, otklanja loše raspoloženje i povećava samopouzdanje.</li>
  <li><strong>Pomaže u mršavljenju:</strong> <a rel="nofollow" href="https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8365736/">Smanjuje</a> telesnu težinu i poboljšava procentualni sastav tela, posebno unutrašnje masti.</li>
</ul>
<h3><strong>Kako da "trenirate"?</strong></h3>
<ul>
  <li><strong>Pronađite aktivnost koja vam prija.</strong> Ne morate da idete u teretanu ili da trčite maraton ako to ne volite. Važno je da pronađete nešto što vam „leži" i što ćete da radite sa zadovoljstvom.</li>
  <li><strong>Počnite polako.</strong> Ako dugo niste bili fizički aktivni, <strong>ne preterujte.</strong> Počnite sa kraćim i lakšim treninzima i postepeno povećavajte intenzitet i trajanje.</li>
  <li><strong>Budite dosledni.</strong> Važno je da <strong>vežbate redovno,</strong> idealno nekoliko puta nedeljno.</li>
  <li><strong>Slušajte svoje telo.</strong> Ako se osećate umorno ili imate bolove, <strong>odmorite se</strong>.</li>
</ul>
<Conclusion>Fizička aktivnost je neizostavan deo zdravog načina života. Pronađite aktivnost koja vam prija i uključite je u svoju rutinu. Vaše telo vapi za ovakvom odlukom i uzvratiće vam na najbolji mogući način!</Conclusion>
<h2><strong>Princip 4: Ubacivanje namirnica - Jedite više zdravog</strong></h2>
<p>Kada ljudi pokušavaju da smršaju ili poboljšaju svoje zdravlje, često se fokus stavlja na izbacivanje „nezdravih" namirnica, preskakanje slatkiša i smanjivanje porcija. Međutim, smatram da je mnogo bitnije baviti se ubacivanjem zdravih namirnica u ishranu. <em>Zašto?</em></p>
<p>Zato što dodavanje zdravih namirnica prirodno dovodi do smanjenja unosa nezdravih namirnica. Kada jedete više voća, povrća, žitarica i proteina, imaćete manje vremena i prostora za prerađenu hranu. Na ovaj način ne samo da ćete poboljšati svoju ishranu, već ćete to uraditi bez gladovanja i odricanja.</p>

<SquareImageLeft src={EighthImage} alt="Description">
<h3><strong>Koje namirnice možete da ubacite u ishranu?</strong></h3>
<ul>
  <li><strong>Žitarice:</strong> Ovsene pahuljice, heljda, ječam, kinoa... Žitarice su bogate vlaknima, vitaminima i mineralima i pružaju dugo osećaj sitosti.</li>
  <li><strong>Povrće:</strong> Brokoli, spanać, šargarepa, paradajz, krastavac, paprika... Povrće je puno vitamina, minerala i antioksidanasa i sadrži malo kalorija.</li>
  <li><strong>Voće:</strong> Jabuke, banane, jagode, borovnice, kivi... Voće je bogato vitaminima, mineralima i vlaknima, a što je najbitnije, slatko je.</li>
  <li><strong>Riba:</strong> Losos, tuna, skuša, sardina... Riba je odličan izvor proteina i omega-3 masnih kiselina, koje su važne za zdravlje srca i mozga.</li>
  <li><strong>Iznutrice:</strong> Džigerica, bubrezi, srce... Iznutrice su bogate hranljivim materijama, uključujući gvožđe, vitamin A i vitamin B12.</li>
</ul>
</SquareImageLeft>
<p>Pored nabrojanih namirnica treba da se osećate slobodno da eksperimentišete i isprobavate različite vrste hrane, kao što su salate, orašasti plodovi, klice, humus, tofu, sir, morski plodovi i drugo. Naravno, niko ne očekuje od vas da uključite sve ove namirnice u svoju svakodnevnu ishranu, ali dodavanje i isprobavanje stvari tu i tamo, može vam pomoći da nađete stvari koje vam se sviđaju, a pride poboljšavaju vašu ishranu.</p>

<h2><strong>Princip 5: Izbacivanje namirnica - Jedite manje nezdravog</strong></h2>
<p>Iako fokus treba da bude na dodavanju zdravih namirnica, važno je i da smanjite unos „nezdravih" namirnica. Međutim odmah bih naglasio:</p>
<p><em> Ne postoji hrana koju nikada ne bi trebalo da jedete.</em>
</p>
<p>Ključ je u umerenosti, samokontroli i balansu.</p>
<p>Kao dobru smernicu, svojim klijentima predstavljam listu koliko je često u redu jesti određenu hranu. Pa tako, dobre namirnice i obroci spadaju u kategoriju možete svaki dan, a hrana malo manjeg kvaliteta je podeljena u tri grupe prema predloženoj učestalosti konzumacije, ali bih napomenuo da treba težiti tome da bude ređe.</p>
<SquareImageLeft src={NinethImage} alt="Description">
<h3><strong>Organizujte manje zdrave namirnice tako da:</strong></h3>
<p><strong>Možete jednom mesečno</strong>
</p>
<ul>
  <li>Hrana pripremljena u dubokom ulju: Pomfrit, pohovano meso, karađorđeva...</li> 
</ul>
<p><strong>Možete jednom nedeljno</strong></p>
<ul>
  <li>Brza hrana: Hamburgeri, pizza, giros...</li>
  <li>Pekarski proizvodi: Burek, peciva, kroasani...</li>
</ul>
<p><strong>Možete više puta nedeljno</strong></p>
<ul>
  <li>Pržena hrana</li>
  <li>Crveno meso</li>
  <li>Sokovi, slatkiši, grickalice</li>
</ul>
</SquareImageLeft>
<p>Odricanje od svega što volite retko dovodi do dugoročnih rezultata. Ako postoji hrana lošijeg kvaliteta koja vam se konkretno jede, pojedite je i nastavite dalje sa svojim zdravim životom. Problem nastaje kada ljudi jedu lošu hranu jer je brza i dostupna, učestalo i u velikim količinama, a ne kada povremeno zadovolje svoju konkretnu želju. Nije cilj da se „mučite" i da se odričete svega što volite. Cilj je da kreirate zdrav i održiv način ishrane koji će vam pomoći da postignete i održite željenu težinu, ali i bolje zdravlje.</p>
<Conclusion>Zdrava ishrana ne znači potpuno odricanje od omiljenih poslastica. Ključ je u balansu, umerenosti i svesnom izboru namirnica koje vam prijaju</Conclusion>
<h2><strong>Zaključak</strong></h2>
<p>U ovom blog postu smo vam predstavili <strong>5 principa za dugoročni uspeh</strong> u mršavljenju bez restriktivnih dijeta i osećaja odricanja.</p>
<p><strong>Ključne poruke su:</strong>
</p>
<ul>
  <li>Jedite svesno i sa uživanjem.</li>
  <li>Budite dosledni.</li> 
  <li>Pokrenite svoje telo.</li>
  <li>Jedite više zdravog.</li>
  <li>Jedite manje nezdravog.</li>
</ul>
<p>Zapamtite, ne postoji čarobni štapić kojim možete postići dobru ishranu. Ipak, uz strpljenje, doslednost i prave strategije, možete da postignete i održite željenu težinu, bolje psihičko i fizičko zdravlje bez toga da prestanete da živite i uživate u životu.</p>
<p>Želite da saznate više o zdravoj ishrani i promeni životnih navika? <a rel="nofollow" href='/contact'>Zakažite svoj prvi sastanak!</a></p>

<p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost7