import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';

import FirstImage from '../blog_images/hrana_i_anksioznost/img1.jpg'
import SecondImage from '../blog_images/hrana_i_anksioznost/img2.jpg'
import ThirdImage from '../blog_images/hrana_i_anksioznost/img3.jpg'
import FourthImage from '../blog_images/hrana_i_anksioznost/img4.jpg'
import FifthImage from '../blog_images/hrana_i_anksioznost/img5.jpg'
import SixthImage from '../blog_images/hrana_i_anksioznost/img6.jpg'

const BlogPost9 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>

<p>Poslednjih pedeset i više godina živimo u dobu koje bismo mogli nazvati naprosto doba anksioznosti. Brzina života, neprekidna reka informacija i konstantan pritisak da stvorimo, napravimo i uspemo učinili su da se stalno osećamo preopterećeno, iscrpljeno i neretko nemoćno. Stres, anksioznost i nedovoljno vremena da brinemo o sebi postali su deo svakodnevice modernog čoveka.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Dok na anksioznost često gledamo isključivo kao na nešto što se odvija u našoj glavi i što je posledica načina na koji se nosimo sa stvarnošću, ovo je ipak samo deo priče. Na anksioznost utiče i dugi niz faktora unutar našeg tela, poput: zdravlja mikrobioma, upalnih procesi, kao i to koliko je prisustvo ključnih nutrijenata u ishrani. Studije su pokazale da disbalans u crevnoj flori može direktno uticati na nivoe stresa i anksioznosti, dok inflamacija često pogoršava simptome. Nutrijenti poput omega-3 masnih kiselina, magnezijuma i cinka imaju ključnu ulogu u podršci mozgu i regulaciji raspoloženja.</p>
<p>Dobra vest je da, iako ne možemo da isključimo spoljne izvore stresa, ono što možemo je da preuzmemo kontrolu nad faktorima koji zavise od nas. Naša ishrana može da bude saveznik ili neprijatelj u borbi protiv anksioznosti i da utiče na našu otpornost na spoljne faktore. Pravilan odabir namirnica, izbor onih koje su bogate nutrijentima, bolja priprema i bolji celokupan odnos prema hrani mogu, ne samo značajno smanjiti negativne posledice savremenog života, već nam i pomoći da povratimo osećaj mira i kontrole. U nastavku ovog teksta ćemo razmotriti kako, zašto i koje konkretne promene u ishrani i navikama mogu unaprediti psihičko stanje čoveka.</p>

<h2>Veza između ishrane i mozga: Mikrobiom i neuroinflamacija</h2>
<p>Iako uglavnom razmišljamo o uticaju hrane na fizičko zdravlje i izgled, propuštamo da su naše telo i mozak nerazdojivo povezani, tako da hrana takođe ima veliki uticaj na naš mozak. Ključni faktor u ovoj vezi je naš crevni mikrobiom, zajednica oko sto biliona (100,000,000,000,000) mikroorganizama koji žive u našim crevima. Ovi mali organizmi su ne samo brojniji od ljudskih ćelija u našem organizmu, već donekle upravljaju našim odlukama putem komunikacije direktno sa našim mozgom, bez našeg znanja, a kroz lučenje hormona i neurotransmitera. Creva i mozak su povezani takozvanom osovinom creva-mozak, koja omogućava dvosmernu komunikaciju putem nervnog sistema, hormona i imunoloških signala. Mikrobiom direktno utiče na proizvodnju neurotransmitera poput serotonina, a ovo znači da nam male bakterije u našim crevima govore da li se osećamo srećno ili tužno. Kada je mikrobiom zdrav i uravnotežen, ovaj sistem funkcioniše dobro i podržava naše mentalno zdravlje, ali…</p>
<SquareImage src={SecondImage} alt="Description">
<p>Neuravnotežen mikrobiom, poznat kao <em>disbioza</em>, povezan je s povećanjem nivoa anksioznosti i stresa. <a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/33271426/">Studija</a> objavljena u časopisu <em>General Psychiatry</em> pokazala je da osobe sa disbiozom češće pate od simptoma anksioznosti, što ukazuje na direktnu vezu između crevnog zdravlja i raspoloženja. <a rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC10146621/">Istraživanja</a> na velikom broju ljudi pokazala su da različit pozitivni uticaj na crevnu floru ima benefite na naše mentalno zdravlje, te da je promena ishrane dodavanjem ribe i omega-3 ulja, uz probiotike, sinbiotike, postbiotike, transplataciju fecesa i drugi načine ona koja menja i naše mentalno, a ne samo fizičko stanje.</p>
<p>Upalni procesi povezani s disbiozom dodatno pogoršavaju ova stanja. Ako posmatramo creva kao stambenu zgradu, izbalansirana creva znače da su svi stanovi zauzeti i u njima žive porodice u slozi, međutim kada dođe do disbioze, ostaje puno praznih stanova u koje može da se useli bilo ko. Neki od novih stanara ove zgrade često nisu dobronamerni i prave pometnju, koja se pokazuje u upali ili inflamaciji - alarmu tela da nešto nije kako bi trebalo da bude. Inflamacija u crevima aktivira naš imunitet, što preko veze creva sa mozgom o kojoj smo pričali, može dovesti do povećanja upalnih procesa u mozgu, odnosno <em>neuroinflamacije</em>. Ovo stanje <a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/32150310/">se smatra</a> glavnim uzrokom problema u funkcionisanju mozga koji dovodi do razvoja anksioznosti i depresije.</p>
</SquareImage>
<p>Ishrana bogata vlaknima i fermentisanim namirnicama može značajno doprineti zdravlju creva. Prebiotici su vrste dijetetskih vlakana koje hrane korisne bakterije u našim crevima, dok probiotici direktno unose korisne mikroorganizme. Kombinacija ove dve vrste hrane doprinosi uravnoteženju mikrobioma i smanjenju upalnih procesa. Evo nekih namirnica koje možete uključiti kako biste uneli više dobrih, ili nahranili već postojeće mikroorganizme:</p>
<ul>
    <li>Ovsene pahuljice i ječam</li>
    <li>Banane</li>
    <li>Luk, beli luk i praziluk</li>
    <li>Jogurt i kefir</li>
    <li>Kiseli kupus i turšija</li>
    <li>Artičoke i špargle</li>
</ul>
<p>Poslednjih godina smo shvatili da poboljšanjem ishrane i pravilnom suplementacijom možemo uticati na disbiozu, smanjiti inflamaciju i poboljšati komunikaciju creva i mozga. Ova saznanja ne samo da ukazuju na značaj pravilne ishrane, već otvaraju vrata ka korišćenju hrane kao alata za prevenciju i lečenje anksioznosti i drugih poremećaja raspoloženja.</p>
<Conclusion>Zdravlje crevnog mikrobioma direktno utiče na naše mentalno stanje, a uravnotežena ishrana bogata vlaknima i fermentisanim namirnicama može smanjiti upalu, poboljšati komunikaciju creva i mozga i pomoći u prevenciji anksioznosti i depresije.</Conclusion>
<h2>Ključni nutrijenti za smanjenje anksioznosti</h2>
<p>Anksioznost pored spoljašnjih stresora i upalnih procesa, takođe može biti posledica narušene unutrašnje hemijske ravnoteže. Određeni nutrijenti imaju značajniju ulogu u održavanju pravilne funkcije mozga, što kroz smanjenje upalnih procesa ili regeneraciju, što kroz direktnu regulaciju raspoloženja. Među najznačajnijim nutrijentima za prevenciju i ublažavanje simptoma anksioznosti su omega-3 masne kiseline, magnezijum, cink, selen i vitamin D.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<h3>Omega-3 masne kiseline</h3>
<p>Omega-3 masne kiseline, a posebno EPA i DHA, dokazano ublažavaju upalne procese u celom telu i igraju ključnu ulogu u održavanju zdravlja mozga. Stavio sam ih na prvo mesto jer je ovo jedan od najčešćih nedostataka u ishrani ljudi u Srbiji i veliki doprinosilac psihičkim i fizičkim problemima. <a rel="nofollow" href="https://bmcpsychiatry.biomedcentral.com/articles/10.1186/s12888-024-05881-2#">Studije</a> su dokazale da adekvatan unos omega-3 masnih kiselina može značajno smanjiti simptome anksioznosti.</p>
<p>Ove masne kiseline mogu se naći u različitoj hrani, ali se u značajnim količinama nalaze u hrani koju mi tradicionalno vrlo slabo konzumiramo. Najbolji izvori su:</p>
<ul>
    <li><strong>Masne ribe</strong> - Losos, skuša, sardina i haringa</li>
    <li><strong>Orasi</strong></li>
    <li><strong>Čija i laneno seme</strong></li>
</ul>
<p>Međutim, osim izvora u hrani suplementacija ribljeg ulja je jednostavna, brza i relativno jeftina opcija.</p>
</SquareImageLeft>
<SquareImage src={FourthImage} alt="Description">
<h3>Magnezijum</h3>
<p>Magnezijum ima puno pozitivnih uticaja koji uključuju smanjenje efekata stresa, regulaciju nervne funkcije i poboljšanje sna. Zato, usled nedostatka magnezijuma dolazi do suprotnih pojava i povećane osetljivosti na stres i anksioznost. Magnezijum je iz mog iskustva relativno čest suplement kod naših ljudi, a najčešći razlozi zašto su ga moji pacijenti dodali u ishranu su grčevi u mišićima ili problemi sa snom. <a rel="nofollow" href="https://www.frontiersin.org/journals/endocrinology/articles/10.3389/fendo.2024.1406455/full">Studije</a> su pokazale da suplementacija magnezijumom može smanjiti simptome anksioznosti kod ljudi sa nedovoljnim unosom ovog minerala, a igra i važnu ulogu u ublažavanju neuroinflamacije o kojoj je već bilo reči.</p>
<p>Prirodno magnezijum se u značajnim količinama može naći u puno namirnica:</p>
<ul>
    <li><strong>Zeleno povrće:</strong> spanać i blitva</li>
    <li><strong>Semenke bundeve i suncokreta</strong></li>
    <li><strong>Avokado</strong></li>
    <li><strong>Pahuljice i integralne žitarice</strong></li>
</ul>
</SquareImage>

<h3>Cink i selen</h3>
<p>Za razliku od magnezijuma koji je telu potreban u relativno velikim količinama, cink i selen spadaju u grupu mikronutrijenata i potrebni su u samo malim količinama. Cink i selen imaju ulogu u antioksidativnim procesima i regulaciji neurotransmitera, pa na taj način podržavaju zdravlje mozga.</p>
<p>Dok postoje neki <a rel="nofollow" href="https://www.sciencedirect.com/science/article/abs/pii/S2405457720300267">pokazatelji</a> uticaja cinka na depresiju i anksioznost, potrebno je još istraživanja kako bismo utvrdili koliko je suplementacija cinkom zaista efikasna, ali za sada možemo reći da je <a rel="nofollow" href="https://pubmed.ncbi.nlm.nih.gov/37364014/">moguće da ima pozitivnih efekata</a>.</p>
<SquareImageLeft src={FifthImage} alt="Description">
<p>Sa druge strane imamo nešto pouzdanije dokaze za pozitivan uticaj adekvatnog unosa selena na anksioznost, kako kod <a rel="nofollow" href="https://www.sciencedirect.com/science/article/abs/pii/000632239190251G">odraslih</a>, tako i kod <a rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC10002467/">dece</a>.</p>
<p>Značajne količine cinka mogu se naći u:</p>
<ul>
    <li><strong>Govedini i piletini</strong></li>
    <li><strong>Semenkama bundeve</strong></li>
    <li><strong>Leblebijama i mahunarkama</strong></li>
</ul>
<p>Dok, su dobri izvori selena:</p>
<ul>
    <li><strong>Brazilski orasi</strong></li>
    <li><strong>Tuna i skuša</strong></li>
    <li><strong>Jaja</strong></li>
    <li><strong>Pečurke, posebno šitake</strong></li>
</ul>
</SquareImageLeft>
<h3>Vitamin D</h3>
<p>Vitamin D je nešto o čemu se od doba korone podosta priča u komercijalnim medijima, tako da bih ga ovde samo spomenuo. Bitan je za sve pa i za mozak i raspoloženje. Dobija se iz sunca, kad ga ima, a iz hrane se može dobiti iz masnih riba i domaćih jaja, i postoji kvalitetna suplementacija za koju je potrebno da se konsultujete sa lekarom, kao i jeftine analize koje možete uraditi u svakoj biohemijskoj laboratoriji.</p>
<Conclusion>Adekvatan unos ključnih nutrijenata poput omega-3 masnih kiselina, magnezijuma, cinka, selena i vitamina D može smanjiti simptome anksioznosti, podržavajući funkciju mozga kroz smanjenje upale, regeneraciju i regulaciju raspoloženja.</Conclusion>
<h2>Antidepresivna ocena hrane (AFS)</h2>
<p>Do sada sam objasnio da uticaj hrane na mentalno zdravlje može da bude višestruko. Sa jedne strane, hrana može da potpomogne dobrim bakterijama u našim crevima koje će onda slati dobre impulse mozgu kroz osovinu creva-mozak, a potom dodatno smanjiti upalne procese u crevima, te posledično i u mozgu. Sa druge strane, iz hrane se mogu dobiti i nadomestiti esencijalni nutrijenti koji direktno utiču na održavanje normalne biohemije mozga. Antidepresivna ocena hrane (AFS) predstavlja indeks razvijen kako bi olakšao pronalaženje hrane koja je bogata esencijalnim nutrijentima koji su važni kod stanja depresije. Visoko mesto na ovom indeksu zauzimaju namirnice bogate ključnim supstancama poput folata, magnezijuma, cinka, omega-3 masnih kiselina i vitamina B-kompleksa, koje imaju dokazanu ulogu u podršci mentalnom zdravlju.</p>
<p><a rel="nofollow" href="https://pmc.ncbi.nlm.nih.gov/articles/PMC6147775/">Najviše ocenjene namirnice prema AFS indeksu</a>:</p>

<h3>Biljnog porekla:</h3>
<ol>
    <li><strong>Potočarka</strong> - Najviši AFS među biljnim namirnicama, izuzetno bogata vitaminima A i C, kao i folatima.</li>
    <li><strong>Spanać</strong> - Bogat izvor magnezijuma, folata i gvožđa.</li>
    <li><strong>Senf, repa i zelena repa</strong> - Puno folata, vitamina C, magnezijuma i antioksidanasa.</li>
    <li><strong>Blitva</strong> - Sadrži visoke nivoe magnezijuma i vitamina C.</li>
    <li><strong>Zelena salata i kelj</strong> - Dobri izvori antioksidanasa i ključnih minerala poput kalcijuma.</li>
</ol>

<h3>Životinjski izvori:</h3>
<ol>
    <li><strong>Ostrige</strong> - Najviši AFS među svim namirnicama, bogate cinkom i vitaminom B12.</li>
    <li><strong>Jetra</strong> (teleća i pileća) - Obiluje gvožđem, vitaminom A i folatima.</li>
    <li><strong>Dagnje i školjke</strong> - Odličan izvor omega-3 masnih kiselina i selena.</li>
    <li><strong>Jaja</strong> - Sadrže vitamin D, B12 i holin, koji podržavaju zdravlje mozga. Napomenuo bih da se ovo odnosi na domaća jaja i da masovno proizvedena jaja mogu imati značajno siromašniji sastav.</li>
</ol>
<Conclusion>Hrana bogata esencijalnim nutrijentima poput folata, magnezijuma, cinka, omega-3 masnih kiselina i vitamina B-kompleksa, rangirana visoko na AFS indeksu, može značajno podržati mentalno zdravlje kroz regulaciju raspoloženja, smanjenje upale i održavanje zdrave biohemije mozga.</Conclusion>
<h2>Hrana koja doprinosi razvoju mentalnih problema</h2>
<RectangleImage src= {SixthImage} alt="Description"></RectangleImage>
<p>Do sada smo objasnili puno o hrani kojom se može sprečiti ili barem zalečiti problem anksioznosti i depresije, međutim, kao što je korisno neke namirnice dodati u ishranu, jednako je korisno da druge izbegavamo. Treba razumeti da povremena i umerena konzumacija ovih namirnica neće napraviti značajan problem i da je bitno jedino kako se ponašamo većinu vremena.</p>

<h3>Rafinisani i prosti šećeri</h3>
<p>Konzumacija rafinisanih i prostih šećera koji su prisutni u slatkišima, slanišima i drugim proizvodima koje možete kupiti u prodavnici, dovodi do velikih oscilacija nivoa šećera u krvi. Nagli skokovi i padovi u nivou šećera, osim zdravstvenih problema, izazivaju i osećaj umora i iritabilnost. Istovremeno, ovakva ishrana negativno utiče na crevnu floru, smanjujući raznovrsnost korisnih bakterija, povećavajući šansu za nastanak disbioze, što dodatno doprinosi razvoju depresije i anksioznosti na način koji smo već objasnili.</p>

<h3>Visoko prerađena hrana i trans-masti</h3>
<p>Visoko prerađena hrana često sadrži velike količine aditiva i trans-masti, koji pored negativnog uticaja na druge sisteme organa, imaju dokazani negativni efekat na povećanje inflamacije u telu, a posebno u crevima. Kao što smo objasnili upalni procesi u crevima mogu izazvati neuroinflamaciju i na taj način pogoršati simptome anksioznosti. Dodatno, trans-masti ometaju metabolizam omega-3 i omega-6 masnih kiselina koje su neophodne za normalno funkcionisanje mozga i odgovor tela na upalu.</p>

<h3>Prekomeran unos alkohola</h3>
<p>Dok alkohol može delovati kao način da se opustimo i smanjimo anksioznost, prekomeran unos dovodi do poremećaja u biohemiji mozga. Česta konzumacija alkohola dovodi do poremećaja sna i povećanog osećaja anksioznosti, posebno kada je praćena društvenom stigmatizacijom i osudom okoline. Takođe, alkohol remeti apsorpciju nekih neophodnih nutrijenata poput vitamina B i magnezijuma, koji su kao što je već rečeno potrebni za održavanje normalne funkcije mozga.</p>

<h3>Unos toksina i zagađivača</h3>
<p>Namirnice zagađene teškim metalima (poput žive u ribi ili kadmijuma u dimljenim proizvodima) mogu doprineti neurotoksičnosti, što direktno utiče na raspoloženje i povećava rizik od depresije i anksioznosti. Takođe, pesticidi i drugi zagađivači prisutni u procesiranoj hrani dodatno ometaju rad nervnog sistema.</p>

<h3>Štetno ponašanje u vezi s ishranom</h3>
<p>Iz svega do sada predočenog može se zaključiti da loš odnos sa hranom vodi pogoršanju, a dobar odnos sa hranom poboljšanju simptoma duševnih bolesti. Preskakanje obroka, prejedanje, konzumacija brze hrane ili ishrana siromašna vlaknima i hranljivim materijama negativno utiču na raspoloženje i nivo energije. Ovakve navike povećavaju rizik od razvoja anksioznosti kroz smanjeni unos esencijalnih nutrijenata i dijetetskih vlakana, destabilizaciju nivoa šećera u krvi, hormonskog disbalansa i opšteg odnosa prema svom fizičkom i psihičkom zdravlju.</p>
<Conclusion>Dok određena hrana može značajno podržati naše mentalno zdravlje, rafinisani šećeri, visoko prerađena hrana, trans-masti i prekomerni unos alkohola imaju suprotan efekat, doprinoseći inflamaciji, narušavanju crevne flore i destabilizaciji biohemije mozga. Ključ leži u izbegavanju ovih namirnica većinu vremena, dok se istovremeno fokusiramo na balansiranu, nutritivno bogatu ishranu koja podržava i telo i um.</Conclusion>
<h2>Zaključak</h2>
<p>Ishrana ne može i ne sme da bude jedini lek u borbi protiv anksioznosti i drugih mentalnih izazova. Ishrana nije zamena za medicinski tretman ili razgovor sa profesionalcem. Ono što ishrana jeste, to je moćan i stalan saveznik u poboljšanju i očuvanju mentalnog zdravlja.</p>
<p>Umerenost, kvalitet, kontinuitet i dobri izbori u svakodnevnoj ishrani mogu značajno smanjiti probleme i unaprediti život.</p>
<p>Počnite malim koracima - povećajte unos ribe, imajte dobru suplementaciju, ispratite šta i koliko jedete. Nije ni potrebno, ni neophodno da sve bude savršeno odmah i sada. Ukoliko se suočavate sa problemima tražite pomoć stručnjaka, nema razloga da situacija bude teža nego što mora.</p>


<p>Uključivanjem ovih namirnica u svakodnevne obroke možemo značajno povećati unos nutrijenata koji pomažu u regulaciji raspoloženja i smanjenju simptoma anksioznosti i depresije.</p>


        <p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost9