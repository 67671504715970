import React from 'react';
import { Container } from '@mui/material';
import CaseStudy from './CaseStudy';
import TestimonialsCarousel from './TestimonialsCarousel';
import Header from '../common/header';
import BookButton from '../common/book';

const TestimonialsPage = () => {
    return (
        <div>


        <Header></Header>
        <Container style={{ marginBottom: '30px' }}>
        <h2 className='white_text'>Kako je Nevena izgubila 18 kilograma i povratila samopouzdanje uz personalizovani pristup ishrani</h2>
        <CaseStudy />
        <h1 style={{ fontSize: '3.2rem', textShadow: '3px 3px 3px #214751', marginBottom: '3VH' }}>Šta kažu moji klijenti</h1>
        <TestimonialsCarousel/>
        </Container>
        <div style={{ paddingBottom:'30px'}}><BookButton ></BookButton></div>
        </div>
    );
};

export default TestimonialsPage;
