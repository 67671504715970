import React from 'react'
import { SquareImage, SquareImageLeft, Conclusion, RectangleImage } from '../blog_visuals.js';

import FirstImage from '../blog_images/istine_i_zablude/img1.jpg'
import SecondImage from '../blog_images/istine_i_zablude/img2.jpg'
import ThirdImage from '../blog_images/istine_i_zablude/img3.jpg'
import FourthImage from '../blog_images/istine_i_zablude/img4.jpg'

const BlogPost10 = () => {
    return (
        <div style={{width:'100VW', overflowWrap: 'anywhere', paddingTop:'15px'}}>

<p>Danas imamo pristup gotovo neograničenoj količini informacija o gotovo svim aspektima života. Razvoj interneta i njegova dostupnost omogućili su praktično svima da se informišu o određenoj oblasti koja ih zanima i stvore svoje lično mišljenje o temi. Na prvi pogled, ovo je fenomenalna stvar jer nas od ogromne memorije ljudskog znanja deli svega nekoliko klikova, a samim tim, čini se, i od odgovora na svako pitanje. Međutim, sada se javljaju potpuno novi problemi, dok naši preci nisu imali način da dođu do znanja, mi ga imamo više nego što možemo da zapamtimo, obradimo i razumemo. Konfuzija izazvana stotinama nepovezanih zaključaka dodatno je pojačana ogromnim brojem, što pravih što lažnih, stučnjaka za čiju stručnost, umesto naučnih krugova ili ostvarenja, garantuje samo broj pratilaca na društvenim mrežama.</p>
<RectangleImage src= {FirstImage} alt="Description"></RectangleImage>
<p>Ishrana spada u kategoriju univerzalnih tema i svako ko jede, dakle svi, oseća se pozvanim da iznese svoje mišljenje na tu temu, pa je količina informacija i stručnjaka za ishranu i veća u odnosu na druge oblasti. Iako naravno da nije problem što svako ima svoje mišljenje o ishrani, problem nastaje onda kada svako može to mišljenje da pusti u etar i obrati se svima koji mogu da ga čuju.</p>
<p>Na ovaj način je oblast ishrane postala vrlo zamućena i nejasna. Postalo je gotovo nemoguće razlikovati proverene i naučno potkrepljene činjenice od mitova, mišljenja i poluistina. Dodatno, otežavajajuća okolnost za razumevanje ove oblasti jesu kompanije, koje rade ono što kompanije i rade - pokušavaju da prodaju svoj proizvod. Ova konfuzija stvara frustraciju i postavlja pitanja: Šta jesti? Šta izbegavati? Šta je zdravo, a šta nezdravo? I najbitnije - kome se može verovati?</p>
<p>Ovaj sajt ima za cilj da pruži jasne i proverene informacije. Nikada vam neću pisati o čarobnim dijetama ili jednostavnim rešenjima jer ih, nažalost, nema. Umesto toga, vodiću vas kroz ono što je realnost ishrane i nutricionizma, kroz proverene informacije i jednostavne promene koje doprinose vašem zdravlju i ciljevima.</p>

<h2>Zašto je nutricionizam zbunjujuć?</h2>
<p>Od svog nastanka, nutricionizam je bio oblast koja se prvenstveno bavi zdravljem. Treba znati da je ovo mlada nauka, nastala u 20. veku i još uvek postoji veliki broj neodgovorenih pitanja. Znamo da dobro kombinovanje namirnica, kvalitetni načini pripreme hrane i izbegavanje nekih supstanci može poboljšati performanse, sprečiti nastanak zdravstvenih problema, pa čak i produžiti život, ali tačni mehanizmi i dokazi za veliki broj tvrdnji tek treba da budu istraženi.</p>
<p>Savremena nutricija već neko vreme nije nauka koja se primarno bavi pitanjima našeg zdravlja, već globalna industrija vredna milijarde evra. Od proizvodnje dodataka ishrani do poznatih ličnosti na društvenim mrežama, svi žele da nam kažu šta da jedemo, kako da se hranimo, šta je najbolje za naše zdravlje, a šta treba da izbegavamo.</p>
<SquareImage src={SecondImage} alt="Description">
<p>Problem je što mnoge od informacija koje stižu do nas nemaju nikakvog naučnog utemeljenja. Na primer, naslovi o čudesnim dijetama ili supernamirnicama koje rešavaju sve probleme koje imate, zanemaruju složenost ljudskog tela i metabolizma. Činjenica je da svi imamo jedinstvene i specifične potrebe, pa čak i kada nešto funkcioniše za jednu osobu, nema nikakvih garancija da će funkcionisati za drugu. <strong>Ishrana je individualna i univerzalna rešenja ne postoje</strong>.</p>
<p>Istraživanja u oblasti ishrane su veoma kompleksna i izazovna. Često zahtevaju svakodnevno višegodišnje praćenje velikih grupa ljudi kako bi se dokazao uticaj samo jedne namirnice ili jednog nutrijenta. Ovo vrtoglavo utiče na cenu istraživanja, traje dugo i ostavlja puno prostora za greške, zbog čega se, iako deluju jednostavno u odnosu na druge naučne grane, na svetu objavljuje poredbeno mali broj kvalitetnih istraživanja u nutriciji. Drugi mnogo jeftiniji i brži način ispitivanja je kroz anketiranje i formulare, ali je hrana toliko ukorenjena navika da ljudi često ne zapamte šta su jeli juče, a kamoli pre mesec ili godinu dana.</p>
</SquareImage>
<p>Iz svih ovih razloga, puno toga što mislimo da znamo o nutriciji je zapravo samo trenutna hipoteza, odnosno pretpostavka zasnovana na ograničenim saznanjima. Kako bi se ove pretpostavke prenele široj populaciji često se uprošćavaju, a iz jedne pretpostavke nastaje puno jednostavnih smernica, što dalje vodi nastanku čitavog mora informacija koje nisu bile pouzdane ni na samom izvoru. Naposletku, kada postanemo preopretećeni, tada jednostavno tražimo nekog da nam jasno i glasno kaže šta da radimo, pa je lako upasti u zamku slepog verovanja najglasnijima.</p>
<Conclusion>Nutricionizam je mlada nauka koja još uvek nema odgovore na mnoga pitanja, ali je njen značaj u unapređenju zdravlja nesporan. Nažalost, previše informacija bez naučnog utemeljenja i uticaj industrije često zamagljuju granicu između činjenica i zabluda.</Conclusion>

<h2>Moj pristup ishrani</h2>
<p>Glavni deo mog posla je filtracija i analiza informacija kroz prizmu naučnih dokaza i iskustva stečenog u višegodišnjem radu sa ljudima. Smatram da je moja uloga da pomognem u razdvajanju proverenih činjenice od zabluda i na osnovu toga pomognem ljudima da donesu odluke koja su, pre svega, utemeljene na znanju, a koje se najbolje uklapaju <strong>sa njihovim željama</strong>.</p>
<SquareImageLeft src={ThirdImage} alt="Description">
<p>Filtracija informacija podrazumeva prepoznavanje ograničenja svakog istraživanja i razumevanje konteksta u kojem su rezultati dobijeni. Na primer, jedno istraživanje može ukazivati na to da određena dijeta smanjuje rizik od bolesti, ali da li je ta dijeta primenjiva na vaš način života? Da li je izvodiva na duže staze? Čokolada smanjuje šansu za hormonski disbalans kod pacova, ali koliko je to značajno za ljude? Tako da pokušavam da vam pomognem da pronađete ravnotežu između naučnih otkrića i praktične primene u svakodnevnom životu. Zato se trudim da u svom pristupu kombinujem ono što nauka nudi i ono što stvarni život zahteva. <strong>Ishrana nije dogma; to je alat koji treba da se prilagodi vama, a ne vi njoj.</strong> Jedna od najvažnijih stvari koju sam naučio kroz rad u ordinaciji jeste da ljudi često previše brinu o ishrani. Naravno, važno je razmišljati o tome šta jedemo, ali sam nebrojeno puta čuo brige koje prelaze granice korisnog. Pitanja poput „Da li će mi ovo ulje od semenki grožđa umesto maslinovog uništiti trud?“ ili „Šta ako pojedem parče hleba posle šest popodne?“ ne doprinose ni zdravijoj ishrani ni zdravijem životu. Umesto toga, prevelika briga stvara stres koji može da donese više štete nego koristi koja bi proistekla od sitnih poboljšanja ishrane.</p>
</SquareImageLeft>
<p>Na ishranu gledam kao na deo šire slike zdravlja. Svaka promena koju napravimo je samo delić našeg ukupnog zdravlja. Ovo znači da ne moramo sve da radimo savršeno, već da je svaki trud u pravom smeru poboljšanje. Umesto perfekcije treba se okrenuti trajnosti i konzistentnosti. Nije bitno šta jedemo za svaki pojedinačan obrok, bitno je šta jedemo većinu vremena. Dobra rečenica koju sam negde pokupio kaže: „<strong>Dobro je često bolje od savršenog</strong>”, što je u slučaju ishrane velika istina.</p>
<p>Druga mana preteranog opterećivanja ishranom osim nepotrebnog stresa je stvaranje neprirodnog odnosa sa hranom. U ordinaciji često pričam sa ljudima koji koriste striktno naučne izraze za svoje svakodnevne navike u ishrani ili mi pričaju koliko su kalorija pojeli za večeru. Ako pričam sa kolegom, ovakav način govora bi bio očekivan, ali se ovde radi o računovođama, domaćicama i programerima. Ovo je za mene pokazatelj problema preopterećenosti informacijam - umišljaj da, samo zato što smo nešto pročitali i razumeli, sada imamo znanje i kapacitet za dubinsko razumevanje čitave oblasti. Informisanost je jedno, znanje je drugo. Nije razlika u tome do kojih ćemo informacija ja ili neko van nutricije da dođemo, razlika je u kontekstu i proceni važnosti. Ako svaku odluku o ishrani doživljavate kao kritično važan deo nekog savršenog procesa, vrlo brzo će doći do preopterećenja, osećaja nemoći da se sve iskontroliše i odbojnosti prema zdravoj ishrani ili čak hrani uopšte. Važno je zapamtiti da <strong>ishrana treba da, pre svega, bude lep i ukusan deo života koji usput podržava i ne ugrožava zdravlje, a ne smisao života i glavni izvor brige</strong>.</p>
<p>Dakle, moj posao je da filtriram informacije, da vam pomognem da razlikujete bitno od nebitnog, istinu od predrasuda. Međutim, jednako važan deo mog posla je da vam kažem kada stati, kako ne preterati i kako imati bolju ishranu zarad boljeg života, a ne živeti bolju ishranu. Radim sa vama kao neko ko vam je saveznik, a ne teret.</p>
<Conclusion>Nutricionizam nije samo nauka o hrani već i razumevanje kako ishrana utiče na naš život, zdravlje i emocionalno stanje. Moj cilj je da pomognem ljudima da pronađu ravnotežu između naučnih činjenica i stvarnog života, kroz filtraciju relevantnih informacija i individualni pristup. Ishrana nije svrha sama po sebi, već alat za bolji, zdraviji i ispunjeniji život.</Conclusion>

<h2>Kako prepoznati šarlatane?</h2>
<p>U širokom svetu interneta često nailazimo na ljude koji se predstavljaju kao stručnjaci za ishranu, ali zapravo šire poluinformacije ili potpune neistine. Ovo je očekivano, jer je ishrana univerzalna tema, pa je svima interesantna i lako je pomisliti da je znanje o nutriciji očigledno ili da se lako može zaključiti sve što ima da se zaključi. Isto možete primetiti i kod drugih, široko rasprostranjenih tema, za šta su dobri primeri: sport i broj sportskih „stručnjaka”, automobili i broj „majstora”, politika i broj „poznavaoca” političke situacije.</p>
<p>Postoji nekoliko osobina po kojima su šarlatani prepoznatljivi:</p>
<p><strong>Znaju sve što ima da se zna </strong>- Nutricija, kao naučna disciplina, je veoma mlada nauka i još uvek se razvija, što i jeste razlog zbog kog svi mi, kao čovečanstvo, još uvek ne znamo sve o njoj. Dodatno, crevni mikrobiom se ispostavio kao jedna od najbitnijih, ako ne i najbitnija stvar vezana za našu ishranu. Čovečiji mikrobiom sastavljen je od oko sto biliona (100,000,000,000,000) pojedinačnih i od oko pet hiljada različitih vrsta mikroorganizama. O tome kako mikrobiom funkcioniše imamo vrlo malo znanja i biće nam potrebne decenije istraživanja da bismo mogli da tvrdimo da znamo bilo šta. Istina je da se nauka o ishrani neprestano menja sa novim istraživanjima, pa pitanja poput „Koja dijeta je najbolja za dugovečnost?“ ili „Da li je kafa dobra ili loša?“ nemaju jednostavne, univerzalne odgovore jer zavise od bezbroj faktora, uključujući genetiku, način života, pa čak i geografsku lokaciju.</p>
<p><strong>Nude jednostavna rešenja </strong>- Znate da se radi o šarlatanu ako čujete da izbacivanje ili ubacivanje jedne namirnice može da napravi razliku. Primera radi: „Sve što treba da uratite da bi smršali je da izbacite hleb” ili „Svi problemi će nestati ako svako jutro pojedete deset bobica aronije”. Činjenica je da ovakve generalizacije jednostavno ne mogu da budu istina za kompleksan sistem kakvo je ljudsko telo, te da su i problemi i rešenja posledica seta navika na duge staze.</p>
<p><strong>Nude brza rešenja </strong>- „Smršajte 25 kilograma za 15 dana”. Kroz razgovor sa ljudima sam primetio da su ljudi postali svesni da ovakva obećanja ne mogu da budu istinita, a čak i kada jesu, da ozbiljno ugrožavaju zdravlje. Jedini način da trajno promenite nešto u svojoj ishrani je kroz dugoročne promene, a dugoročne promene zahtevaju vreme. Setite se da je kilaža broj na vagi i pokazatelj problema, a ne sam problem. Samim tim, fokus na smanjenju kilaže je poput uzimanja lekova za spuštanja temperature, bez istinskog bavljenja bolešću zbog koje je temperatura uopšte skočila.</p>
<p><strong>Nude apsolutna rešenja</strong> - „Jedini način da ovo je da ono” Dok kompleksnost ljudskog metabolizma, sa jedne strane, otežava razumevanje i proučavanje čoveka, sa druge strane omogućava da za svaki problem postoji alternativno rešenje. Svojim pacijentima objašnjavam da je rešavanje problema poput puta od kuće do škole: postoji najkraći put, ali se na isto mesto može stići i brojnim drugim putevima. Takođe, postoje i suprotne tvrdnje koje glase: „Ovo ne smeš nikada da jedeš jer…” Potpuna neistina! Ne postoji hrana koja ne sme nikada da se jede. Neku hranu treba jesti malo češće, neku malo ređe, ali šta god da vam se jede, slobodno ga pojedite, završite sa tim i nastavite dalje. Hrana treba da bude izvor uživanja i zadovoljstva, samo ne mora baš svaki obrok da bude najveće moguće uživanje. Problem ne nastaje kada ljudi jedu lošu hranu jer im se jede i jer uživaju u njoj, nego onda kada ljudi jedu lošu hranu iz navike ili jer je jedina dostupna. Dakle, <strong>uživajte sa merom</strong>.</p>
<p><strong>Marketing</strong> - Poslednja grupa nisu stvarno šarlatani, već ljudi koji pričaju i rade stvari iz direktne dobiti. Ova grupa nažalost može uključivati i ljude koji su renomirani i stručni poput nutricionista, ljudi iz sveta fitnesa i lekara, ali imaju lični interes kao prioritet, a ne vaš. Kao i za sve druge informacije i za ishranu treba ostati skeptičan i kada čujete novu informaciju dobro je zapitati se: Gde su dokazi? Ko stoji iza te tvrdnje? Da li je to zasnovano na istraživanjima ili na trendovima? <strong>Kritičko razmišljanje je jedino oruđe koje imamo za razlikovanje informacija od dezinformacija</strong>.</p>
<p>Nutricija je mlada i niko ne zna sve, oni koji tvrde da znaju sve, često ne znaju ništa. Ishrana nije jedna veličina koja odgovara svima, niti postoji jedan pravi put ka zdravlju, oni koji tvrde suprotno jednostavno nisu u pravu. Ishrana je najstarija navika koju imamo pa promena zahteva vreme, svi koji kažu da može brzo ne rade za vas. Nutricija je multimilionska industrija, te svi hoće da prodaju neko univerzalno rešenje, ali zapamtite - nema ga.</p>
<Conclusion>Prepoznavanje šarlatana u nutricionizmu zahteva kritičko razmišljanje i svest o tome da nijedna dijeta, namirnica ili rešenje nije univerzalno.Uvek se zapitajte gde su dokazi i budite skeptični prema onima koji nude jednostavna, apsolutna i brza rešenja.</Conclusion>

<h2>Vrednost kontinuiranog učenja</h2>
<p>Kroz sve godine rada u oblasti ishrane, naučio sam da je promena mišljenja prirodan i neizbežan deo kontinuiranog učenja. Postoji puno stvari za koje sam ranije verovao da su najbolje, da bih kasnije, kroz nova istraživanja ili iskustva u radu sa ljudima, shvatio da postoje bolja rešenja. Smatram da ovo nije posledica nedostatka znanja, već proces njegovog rasta. Nauka o ishrani se stalno razvija, a moje razumevanje se menja zajedno s njom. Lična poniznost u tome da prihvatim nova saznanja i prilagodim svoj pristup nešto je na šta sam ponosan, jer verujem da je svaki korak napred donosi dobit ljudima sa kojima radim i ljudima sa kojima ću tek raditi.</p>
<RectangleImage src= {FourthImage} alt="Description"></RectangleImage>
<p>Jedna od najvažnijih lekcija koje sam naučio jeste da, koliko god da sam stručan u biohemiji i nutriciji moji klijenti poseduju jedinstveno znanje - oni su stručnjaci za sopstveni život. Samo oni znaju šta može da se uklopi u njihov ritam, šta može postati trajna navika i kako će na kraju dana primeniti preporuke. Iz ovoga sledi da, čak i da znam sve što ima da se zna o ishrani (a ne znam), polovina znanja koje mi je potrebno da nekome pomognem će uvek biti zaključana za mene. Moja uloga nije da ljudima dam univerzalna pravila, već da kroz saradnju sa njima zajedno pronađemo rešenja koja su istovremeno naučno utemeljena i uklopiva u nečiju svakodnevicu.</p>
<p><strong>Kontinuirano učenje i spremnost na promenu mišljenja nisu znak slabosti, već snage</strong>. Niko od nas nema sve odgovore, niti treba očekivati da ih neko ima. Učenje je proces i dok znamo šta je bolja ishrana, šta je najbolja ishrana i dalje niko ne zna.</p>
<Conclusion>Razumevanje da niko ne zna sve i uvažavanje individualnih potreba svakog klijenta osnova su pristupa koji spaja naučna saznanja sa praktičnom primenom u svakodnevnom životu. Snaga leži u prilagodljivosti, saradnji i stalnom rastu.</Conclusion>

<h2>Zaključak</h2>
<p>Ishrana je mnogo više od broja kalorija ili izbora namirnica - ona je deo svakog našeg dana, našeg zdravlja i našeg odnosa prema sebi. U svetu preplavljenom informacijama i dezinformacijama, izazov toga šta jesti je manje značajan od toga kako doživljavamo hranu i kako odlučujemo šta je najbolje za nas.</p>
<p>Savršenstvo nije ono što je bitno, već trajne i realne promene koje možemo uključiti u svoj život. Filtriranje informacija, kritičko razmišljanje i spremnost na učenje su, danas više nego ikada, ključni alati za razumevanje ogromne količine saznanja koja do nas dolazi. Ishrana nije univerzalna; ona je duboko individualna i treba da podrži vaš način života, a ne da postane njegov smisao.</p>
<p>Važno je zapamtiti: niko ne zna sve odgovore. Nutricionizam se razvija, baš kao i naše razumevanje tela i njegovih potreba. Ono što ja nudim nisu gotova rešenja, već da vas podržim u procesu istraživanja, prilagođavanja i donošenja odluka koje su najbolje za vas. Dobra ishrana nije samo zdrava, već i održiva. Alat za kvalitetniji, a ne stresniji život.</p>
<p>Hvala vam što ste izdvojili vreme da pročitate ovaj tekst u kome sam probao da prenesem svoje razumevanje oblasti kojom se bavim. Ako bih želeo da ceo tekst sumiram u samo jednoj rečenici, ta rečenica bi bila: „Cilj nikada ne treba da bude savršenstvo, već kontinuirani napredak”.</p>

        <p>Jo&scaron; zanimljivih tekstova možete pročitati na mom <a href="/science/">BLOG-u</a>.</p>
        </div>
    )
}
export default BlogPost10