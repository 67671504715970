import React, { useState, useRef, useEffect } from 'react';
import { Container } from '@mui/system';
import Header from '../common/header';
import services from './ServiceBlock';
import BookButton from '../common/book';

const ServiceBlock = ({ id, title, image, onClick }) => (
    <div onClick={() => onClick(id)} className="service-block">
        <img alt={title} src={image} />
        <p>{title}</p>
    </div>
);

const ServicePage = () => {
    const [openService, setOpenService] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Provera veličine ekrana
    const contentRef = useRef(null); // Referenca za tekst

    const toggleOpen = (id) => {
        setOpenService(openService === id ? null : id);
    };

    useEffect(() => {
        // Dodavanje listener-a za promenu veličine prozora
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (openService && contentRef.current) {
            contentRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [openService]);

    return (
        <div style={{ minHeight: '100vH' }}>
            <Header />
            <Container>
                <h1 style={{ fontSize: '2.5rem', marginTop: '20px', textAlign: 'center' }}>
                    Kako jednostavne promene mogu da dugotrajno poboljšaju tvoju ishranu i zdravlje
                </h1>
                <p className="white_text horizontal-padding-for-phone">
                    Kao što naslov kaže, ono što ti nudim jeste dugotrajno rešenje tvojih problema sa ishranom i zdravljem. 
                    Odmah mogu da ti obećam da će proces biti veoma lak i jednostavan, toliko da ćeš se na kraju zapitati 
                    „Zašto ovo nisam pre uradio/la?”. Na žalost teško je promeniti sam sebe, zato sam tu da ti dam motivaciju i savete kako bi ostvario/la svoje ciljeve. Moji pristup je kolaborativan, jer sam svestan da ti najbolje poznaješ sebe, pa je na meni da ti pomognem da sam dođeš do najboljeg načina da izbalansiraš svoji život sa održivim dobrim navikama. Ispod možeš videti koje sve usluge nudim.
                </p>
                <Container style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', padding: '20px' }}>
                    {services.map((service, index) => (
                        <React.Fragment key={service.id}>
                            <ServiceBlock
                                id={service.id}
                                title={service.title}
                                image={service.image}
                                onClick={toggleOpen}
                            />
                            {isMobile && openService === service.id && (
                                // Tekst se prikazuje između dugmića samo na telefonu
                                <div
                                    ref={contentRef}
                                    style={{
                                        marginTop: '10px',
                                        marginBottom: '10px',
                                        width: '100%',
                                        backgroundColor: '#f9f9f9',
                                        padding: '20px',
                                        borderRadius: '10px',
                                    }}
                                >
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: services.find((s) => s.id === openService).content,
                                        }}
                                    ></div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </Container>
                {!isMobile && openService && (
                    // Na računaru tekst ostaje ispod svih slika
                    <div
                        ref={contentRef}
                        style={{
                            marginTop: '20px',
                            width: '85%',
                            margin: '0 auto',
                            marginBottom: '20px',
                            backgroundColor: '#f9f9f9',
                            padding: '20px',
                            borderRadius: '10px',
                        }}
                    >
                        <div
                            dangerouslySetInnerHTML={{
                                __html: services.find((service) => service.id === openService).content,
                            }}
                        ></div>
                    </div>
                )}
                <BookButton/>
                <div style={{padding:'20px'}}></div>
            </Container>
        </div>
    );
};

export default ServicePage;
